* {
  outline: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Smooch Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  margin: 0;
  box-sizing: border-box;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Smooch Sans";
  overflow: hidden;
  transition: all ease 0.33ms;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important;
}

.ohm-card {
  max-width: 833px;
  .card-header {
    width: 100%;
    min-height: 33px;
    margin-bottom: 10px;
    h5 {
      font-weight: 600 !important;
    }
  }

  .help-text {
    margin: 10px 0px;
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 12px 0px;
  }

  .data-row-centered {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 12px 0px;
    .marginedBtn {
      margin: 0.3rem;
    }
  }
}

.ohm-pairs {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

a:hover svg {
  color: inherit;
}

.tooltip {
  z-index: 9999999;
}
