.airdropview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 24px;
    margin-bottom: 25px;
  }
  .airdropitem {
    width: 50%;
    padding: 20px 30px 30px 30px;
    margin: auto;
    h5 {
      font-size: 1.3118rem;
      padding-bottom: 16px;
    }

    button {
      width: 100%;
    }
  }
  .airdrop-card {
    width: 97%;
    padding: 20px 30px 30px 30px;
    max-width: 833px;
    border-radius: 10px;
    margin-bottom: 1.8rem;
    background-color: white;
    justify-content: center;
    align-items: center;
    .card-header {
      width: 100%;
      min-height: 33px;
      margin-bottom: 10px;
      h5 {
        font-weight: 600 !important;
      }
    }
  }
}
